import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Privacy Policy</h1>
    <p>
        Archives does not store or transmit any personally identifiable information. All of your data generated in Archives is stored on your device or iCloud.
        Microsoft App Center is used for analytics and crash reporting.
    </p>
  </Layout>
)

export default PrivacyPolicy